import { useEffect } from 'react';
import { useState } from 'react';
import Logo from '../images/VIVO_Logo_312x96.png';
import ContactModal from '../Modal';
import { AppBar, Button, Grid, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import NavItem from './NavItem';

function Navbar() {
  const [modal, setModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xl"))
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("lg"))


  useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1200;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);


  function toggleModal() {
    setModal(!modal);
  };

  return (
    <>
    {
      modal && (
        <ContactModal setToggleModal={toggleModal}/>
      )
    }
    <AppBar id="header-navbar" position="fixed" sx={{ bgcolor: '#000', height: '5em' }}>
      <Toolbar style={{ height: isMobile ? '5px': '55px' }}>
          <a
            aria-label="logo"
            href="."
            role="navigation"
            style={{ height: '55px' }}
          >
            <img
              src={Logo}
              alt="Vivosurgery Logo"
              width="195"
              height="70"
              style={{ marginTop: '0.2em', marginLeft: isSmall ? '1em': '15em'}}
            />
          </a>
        <div style={{ marginLeft: 'auto', marginTop: '1em'}}>
        {/* <div style={{ marginLeft: '50%', marginTop: '1em'}}> */}
          <Button
            variant="outlined"
            style={{ background: "transparent", marginRight: "1em", width: '10em', color: '#26b0c5' }}
            href="https://vivosurgery.io/landing/login"
          >
            Log in
          </Button>
          <Button
            variant="contained"
            style={{ background: '#26b0c5', width: '10em'  }}
            data-target="contact-us-"
            onClick={() => toggleModal()}
          >
              Contact Us
          </Button>
        </div>
      </Toolbar>
    </AppBar>
    <section className="section is-medium" style={{background: '#000', padding: '3em 0em 5em 0em', 'marginTop': '4em'}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography fontSize={'4em'} marginLeft={isSmall ? "1em" : "4.5em"} align="left" variant="h1" color="#fff">Scale <br/> Mastery.</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{lineHeight: 0.9, letterSpacing: 1.5}} fontWeight={'600'} marginLeft={"15%"} paddingRight={'10em'} align="left" fontSize={'1.2em'} color='#fff'>We make experiential learning possible in live operating rooms</Typography>
          <Typography sx={{letterSpacing: 1.5}} paddingRight={'15em'} marginLeft={"15%"} align="left" fontSize={'1em'} color='#fff'><br/>Vivo Surgery is an all-in-one platform connecting higher education programs
to digital clinical placements in surgery.
</Typography>
          <div className="is-pulled-left" style={{ marginLeft: '15%', marginTop: '1em'}}>
            <Button
              // variant="outlined"
              style={{ background: "transparent", color: '#26b0c5' }}
              href="https://vivosurgery.io/landing/login"
            >
              <p>
                Let's Go 
                <i className="fa fa-arrow-right ml-1"></i>
              </p>
            </Button>
          </div>
        </Grid>
      </Grid>
    </section>
      {/* <nav
        className="level is-mobile"
        role="navigation"
        aria-label="main-nav"
        style={{background: '#0a0a0a', marginBottom: '0'}}
      >
        {
          modal && (
            <Modal setToggleModal={toggleModal}/>
          )
        }
          <div className={`navbar-brand ${isMobile ? "mobile-nav-bar" : "desktop-nav-bar"}`}>
          <nav className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <a
                    aria-label="logo"
                    className="navbar-item"
                    href="."
                    role="navigation"
                    // style={{marginLeft: "300%"}}
                >
                  <img
                      src={Logo}
                      alt="Vivosurgery Logo"
                      width="96"
                      height="312"
                  />
                  </a>
              </div>
            </div>
            <div class="navbar-end" style={{marginRight: "20%"}}>
            <div className="level-right">
              <div class="navbar-item">
              <div className="level-item">
                <div class="buttons">
                  <a class="button is-black is-size-7" href="https://vivosurgery.io/landing/login">
                    Log in
                  </a>
                </div>
              </div>
              <div className="level-item">
                  <a
                    class="button is-primary is-size-7 has-text-dark"
                    style={{borderRadius: "25Px", background: '#26b0c5', marginRight: '1em'}}
                    data-target="contact-us-"
                    onClick={() => toggleModal()}
                  >
                    <strong>Contact Us</strong>
                  </a>
              </div>
            </div>
          </nav>
      </nav> */}
      </>
    )
}

export default Navbar;