import './style.scss'
import background from './images/Vivo_boardroom.jpg'
import { Box, Button, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, useFormik } from 'formik';
import { InputField, DescriptionField } from './elements';
import emailjs from 'emailjs-com';
import * as Yup from 'yup';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ContactInfo() {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("md"))
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)

  const schema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    institution: Yup.string().required('Required'),
    email: Yup.string().required('Required').email('Invalid email address'),
    message: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      institution: null,
      email: null,
      message: null,
    },
    validationSchema: schema,
    validateOnMount: true,
  })

  function sendEmail(e) {
    e.preventDefault();
    emailjs.send('service_uq1sl4n', 'template_sys9jl8', formik.values, 'Cmj9VpwpnB_UROGkx');
    setOpen(true);
  //   setToggleModal()
  }
    return (
      <>
        <Modal
          className="modal is-active is-small"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box sx={{ ...style, width: 400 }}>
            <div className="level">
              <Typography variant="h5" className="m-5">Email sent!</Typography>
              <button
                style={{ marginLeft: 'auto'}}
                className="delete"
                onClick={() => setOpen(false)}
                aria-label="close"
                ></button>
            </div>
              <p id="parent-modal-description" className="m-2">
              Thank you for your inquiry! <br/><br /> Someone from the VivoSurgery team will be reaching out to you shortly.
              </p>
              <Button
                variant="outlined"
                sx={{ color: '#26b0c5', marginTop: '1em' }}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
          </Box>
        </Modal>
        <div 
          style={{ maxHeight: '700px', overflow: 'hidden' }}
        >
          <img
            alt='backgroundProposition'
            src={background}
            style={{
              objectFit: 'cover',
              filter: 'brightness(0.3)',
              position: 'absolute',
              zIndex: '-1',
              left: '0',
              overflow: 'hidden',
              width: '100%',
              height: '700px'
          }}
          />
          <section className="hero is-medium">
            <div className="hero-body">
              <div className="container" style={{maxWidth: isExtraSmall ? '90%': '40%', marginTop: '-5em'}}>
                <Typography variant="h3" style={{ color: 'white', fontWeight: '500', textAlign: 'center' }}>
                  Leave us a message and we'll get back to you.
                </Typography>
                <Formik
                  validationSchema={schema}
                  validateOnChange
                  validateOnMount
                  onSubmit={(values) => console.log('SUBMITTING VALUES: ', values)}
                  >
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="columns is-multiline has-text-dark mt-2 is-mobile">
                      <div className="column is-half">
                        <Field
                          component={InputField}
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                        />
                      </div>
                      <div className="column is-half">
                        <Field
                          component={InputField}
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                        />
                      </div>
                      <div className="column is-half">
                        <Field
                          component={InputField}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                      <div className="column is-half">
                        <Field
                          component={InputField}
                          id="institution"
                          name="institution"
                          type="text"
                          placeholder="Business/Institution"
                          onChange={formik.handleChange}
                          value={formik.values.institution}
                        />
                      </div>
                      </div>
                      <Field
                        component={DescriptionField}
                        id="message"
                        name="message"
                        type="text"
                        placeholder="Message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      />
                    </Form>
                  </Formik>
                  <Button
                    variant="contained"
                    style={{
                      background: '#26b0c5',
                      marginTop: '2em',
                      color: 'white',
                      position: 'relative',
                      left: isSmall ? '35%' : '40%'
                    }}
                    onClick={sendEmail}
                    disabled={!!!formik.isValid}
                  >
                    Send Message
                   </Button>

              </div>
            </div>
          </section>
        </div>
      </>
    )
}

export default ContactInfo;